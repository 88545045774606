import React from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { FaRegClock, FaCalendarAlt } from 'react-icons/fa';

function BlogPost({ title, summary, date, readTime, tags }) {
  return (
    <Card className="blog-post">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className="blog-meta">
          <span className="date">
            <FaCalendarAlt /> {date}
          </span>
          <span className="read-time">
            <FaRegClock /> {readTime}
          </span>
        </div>
        <Card.Text>{summary}</Card.Text>
        <div className="blog-tags">
          {tags.map((tag, index) => (
            <Badge 
              key={index} 
              bg="secondary" 
              className="me-2"
            >
              {tag}
            </Badge>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

export default BlogPost; 