import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null 
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.group('Error Boundary Caught Error:');
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
    console.error('Component Stack:', errorInfo.componentStack);
    console.groupEnd();
    
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container" style={{
          padding: '20px',
          textAlign: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white'
        }}>
          <h2 style={{ marginBottom: '20px' }}>
            {this.props.language === 'fr' 
              ? 'Quelque chose s\'est mal passé' 
              : 'Something went wrong'}
          </h2>
          <button
            onClick={() => {
              this.setState({ hasError: false });
              window.location.reload();
            }}
            style={{
              padding: '10px 20px',
              backgroundColor: '#ff7b00',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            {this.props.language === 'fr' 
              ? 'Rafraîchir la page' 
              : 'Refresh Page'}
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Wrapper to provide language context
export function ErrorBoundaryWithLanguage(props) {
  const { language } = useLanguage();
  return <ErrorBoundary language={language} {...props} />;
} 