import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/dev cartoon.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { FaArrowDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

function Home() {
  const { language } = useLanguage();
  const t = translations[language].home;

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content hero-section">
          <Row>
            <Col md={7} className="home-header animate__animated animate__fadeIn">
              <div className="hero-intro">
                <h1 className="heading">
                  {t.greeting}{" "}
                  <span className="wave" role="img" aria-labelledby="wave">
                    👋🏻
                  </span>
                </h1>

                <h1 className="heading-name">
                  {t.intro}
                  <strong className="main-name"> {t.name}</strong>
                </h1>

                <div className="heading-description">
                  <p>{t.subtitle}</p>
                  <p className="sub-description">{t.description}</p>
                </div>
              </div>

              <div className="cta-section">
                <Link to="/services" className="primary-cta">
                  {t.cta.services}
                </Link>
                <Link to="/blog" className="secondary-cta">
                  {t.cta.learnMore}
                </Link>
              </div>

              <div className="type-container">
                <Type skills={t.typewriter.skills} />
              </div>

              <div className="scroll-indicator">
                <FaArrowDown className="scroll-arrow" />
                <span>{t.scroll}</span>
              </div>
            </Col>

            <Col md={5} className="home-image animate__animated animate__fadeInRight">
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid floating-image"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
