export const translations = {
  en: {
    nav: {
      home: "Home",
      about: "About",
      projects: "Projects",
      services: "Services",
      blog: "Blog",
      resume: "Resume"
    },
    services: {
      title: "Professional Services",
      subtitle: "Choose from our range of professional services tailored to your needs",
      tiers: {
        black: "Black Tier",
        gold: "Gold Tier",
        features: "Features",
        price: "Price"
      },
      buttons: {
        select: "Select",
        continue: "Continue",
        cancel: "Cancel"
      },
      modal: {
        title: "Enter Your Details",
        phone: "Enter your phone or WhatsApp number",
        success: "Thank You!",
        successMessage: "Your service request has been received.",
        contactInfo: "We will contact you shortly at:",
        ariaLabel: "Service request form"
      },
      errors: {
        requiredFields: "Please fill in all required fields",
      },
      meta: {
        title: "Professional Services | Austin Elongo",
        description: "Professional web development, mobile app development, and digital services in Cameroon",
        keywords: "web development, mobile apps, digital services, Cameroon, professional services"
      },
      loading: {
        submitting: "Submitting request...",
        redirecting: "Redirecting to contact page..."
      }
    },
    home: {
      greeting: "Hi There!",
      intro: "I'M",
      name: "AUSTIN ELONGO",
      subtitle: "Crafting Digital Excellence | Web Developer | Mobile App Developer",
      description: "Transforming innovative ideas into seamless digital experiences that drive business growth",
      cta: {
        services: "Explore Services",
        learnMore: "Learn More"
      },
      scroll: "Scroll to discover more",
      typewriter: {
        skills: [
          "Full Stack Developer",
          "Mobile App Developer",
          "UI/UX Designer",
          "Open Source Contributor"
        ]
      }
    },
    about: {
      title: "Know Who",
      emphasis: "I AM",
      sections: {
        skills: "Professional Skills",
        professional: "Professional",
        skillset: "Skillset",
        tools: "Development Tools",
        toolsEmphasis: "Tools",
        toolsText: "I use"
      },
      resume: {
        title: "Want to know more?",
        description: "Download my resume to learn about my experience and qualifications",
        button: "Download Resume"
      },
      serviceRequest: {
        title: "Service Request Received!",
        service: "Service",
        tier: "Tier",
        contact: "We will contact you at",
        assistance: "For immediate assistance"
      },
      intro: {
        title: "LET ME",
        highlight: "INTRODUCE",
        myself: "MYSELF",
        location: "from Buea, Cameroon",
        job: "software developer at Njix",
        education: "Bachelor of Science (BSc) in Software Engineering",
        activities: "Apart from coding, some other activities that I love to do!",
        passion: "I fell in love with programming and I have at least learnt something, I think…"
      },
      skills: {
        intro: "I am fluent in",
        languages: "Javascript and React, Java, C++, Flutter and Dart"
      },
      interests: {
        intro: "My field of Interest's are building new",
        primary: "Web Technologies and Products with Js, Mobile Apps with Flutter Technologies",
        secondary: "Blockchain and AI"
      },
      flexibility: {
        intro: "Whenever necessary, I also apply my passion for developing products with",
        tech: "Other Technologies",
        and: "and a lot of",
        key: "flexibility",
        outro: "depending on the team needs and company",
        requirements: "Requirements"
      },
      social: {
        title: "FIND ME ON",
        intro: "Feel free to",
        connect: "connect",
        outro: "with me"
      },
      hobbies: [
        "Playing Games",
        "Watching Scifi Tv shows",
        "Watching Football Games"
      ],
      quote: "Strive to improve and make a difference!"
    },
    projects: {
      title: {
        recent: "My Recent",
        works: "Works"
      },
      subtitle: "Here are a few projects I've worked on recently.",
      buttons: {
        github: "GitHub",
        demo: "Demo",
        viewCode: "View source code",
        viewDemo: "View live demo"
      },
      projectList: [
        {
          title: "Zap Nteng",
          description: "A mobile and web platform for the retail of all footwear products, for Zaps shoes. Built with React.js. Prioritizing efficiency and optimized for user experience and usability.",
          image: "/Assets/Projects/zapntengDemo.jpg",
          githubLink: "https://github.com/austin-elongo/zap-nteng",
          demoLink: ""
        },
        {
          title: "TPR USA",
          description: "Build with Shopify for Triangle Party Rentals USA. Implementing intergrated secure payments, secure bookings, secure chat, quick favouriting, Gallery and much more.",
          image: "/Assets/Projects/TPRDemo.jpg",
          githubLink: "",
          demoLink: "https://www.triangle-party-rentals.com/"
        },
        {
          title: "Editor.io",
          description: "Online code and markdown editor build with react.js. Online Editor which supports html, css, and js code with instant view of website. Online markdown editor for building README file which supports GFM, Custom Html tags with toolbar and instant preview.Both the editor supports auto save of work using Local Storage",
          image: "/Assets/Projects/codeEditor.png",
          githubLink: "",
          demoLink: ""
        },
        {
          title: "Plant AI",
          description: "Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model.",
          image: "/Assets/Projects/leaf.png",
          githubLink: "",
          demoLink: ""
        },
        {
          title: "Pidgin Recognition AI Project",
          description: "Cloud-based NLP framework that enhances the context-awareness and intent recognition capabilities of voice assistants in the African e-commerce sector. A project build with Python, Jupiter Notebook, Js, Pandas, Numpy, Aws Lamda, PyTorch, Llama4 finetunning and Google colab",
          image: "/Assets/Projects/suicide.png",
          githubLink: "https://github.com/austin-elongo/Pidgin-Recognition-Ai",
          demoLink: "",
          docLink: "https://docs.google.com/document/d/1dxEKVdaeDyb5dZcRR5MjfJDc7DxYMV4M/edit?usp=sharing&ouid=103254439432642910469&rtpof=true&sd=true"
        },
        {
          title: "Austins Personals",
          description: "My personal website built with MERN Stack. It uses React.js, Css, html, Ts and Versel for deployement. It is designed with an ellegant techy purple which is visualy comfortable and black for optimization purposes",
          image: "/Assets/Projects/portfolioDemo.jpg",
          githubLink: "https://github.com/austin-elongo/AustinElongo",
          demoLink: ""
        }
      ]
    },
    blog: {
      title: {
        insights: "Tech Insights &",
        tips: "Development Tips"
      },
      subtitle: "Expert insights on web and mobile development in Cameroon",
      posts: [
        {
          title: "Why Your Cameroonian Business Needs a Mobile App in 2024",
          summary: "Discover how mobile apps can transform your business in Cameroon's growing digital market...",
          date: "February 2024",
          readTime: "7 min read",
          tags: ["Cameroon Business", "Mobile Apps", "Digital Transformation"]
        },
        {
          title: "E-commerce Solutions for Cameroon: A Complete Guide",
          summary: "Everything you need to know about launching an e-commerce business in Cameroon...",
          date: "February 2024",
          readTime: "7 min read",
          tags: ["E-commerce", "Cameroon", "Online Business"]
        }
      ]
    },
    resume: {
      downloadButton: "Download CV",
      pageTitle: "My Resume",
      loading: "Loading PDF...",
      error: "Error loading PDF"
    },
    footer: {
      designedBy: "Designed and Developed by Austin Elongo",
      copyright: "Copyright © {year} AE",
      socialLinks: {
        github: "Visit GitHub profile",
        twitter: "Visit Twitter profile",
        linkedin: "Visit LinkedIn profile",
        instagram: "Visit Instagram profile"
      }
    },
    errors: {
      required: "This field is required",
      invalidEmail: "Please enter a valid email address",
      invalidPhone: "Please enter a valid phone number",
      minLength: "Must be at least {min} characters",
      maxLength: "Must not exceed {max} characters",
      networkError: "Network error. Please check your connection",
      serverError: "Server error. Please try again later",
      unauthorized: "Unauthorized access",
      notFound: "Resource not found",
      saved: "Successfully saved",
      updated: "Successfully updated",
      deleted: "Successfully deleted",
      sent: "Successfully sent"
    },
    meta: {
      defaultTitle: "Austin Elongo | Full Stack Developer",
      defaultDescription: "Professional Full Stack Developer specializing in web development, mobile apps, and digital solutions in Cameroon",
      defaultKeywords: "web development, mobile apps, SEO, digital solutions, Cameroon",
      languageSelector: "Switch language to",
      mainRegion: "Cameroon",
      businessHours: "Mon-Fri 9:00-17:00 WAT"
    }
  },
  fr: {
    nav: {
      home: "Accueil",
      about: "À propos",
      projects: "Projets",
      services: "Services",
      blog: "Blog",
      resume: "CV"
    },
    services: {
      title: "Services Professionnels",
      subtitle: "Choisissez parmi notre gamme de services professionnels adaptés à vos besoins",
      tiers: {
        black: "Niveau Black",
        gold: "Niveau Gold",
        features: "Caractéristiques",
        price: "Prix"
      },
      buttons: {
        select: "Sélectionner",
        continue: "Continuer",
        cancel: "Annuler"
      },
      modal: {
        title: "Entrez vos coordonnées",
        phone: "Entrez votre numéro de téléphone ou WhatsApp",
        success: "Merci !",
        successMessage: "Votre demande de service a été reçue.",
        contactInfo: "Nous vous contacterons bientôt au:",
        ariaLabel: "Formulaire de demande de service"
      },
      errors: {
        requiredFields: "Veuillez remplir tous les champs obligatoires",
      },
      meta: {
        title: "Services Professionnels | Austin Elongo",
        description: "Services professionnels de développement web, d'applications mobiles et services numériques au Cameroun",
        keywords: "développement web, applications mobiles, services numériques, Cameroun, services professionnels"
      },
      loading: {
        submitting: "Envoi de la demande...",
        redirecting: "Redirection vers la page de contact..."
      }
    },
    home: {
      greeting: "Bonjour!",
      intro: "JE SUIS",
      name: "AUSTIN ELONGO",
      subtitle: "Excellence Numérique | Développeur Web | Développeur d'Applications Mobiles",
      description: "Transformer des idées innovantes en expériences numériques fluides",
      cta: {
        services: "Explorer les Services",
        learnMore: "En Savoir Plus"
      },
      scroll: "Défilez pour découvrir plus",
      typewriter: {
        skills: [
          "Développeur Full Stack",
          "Développeur d'Applications Mobiles",
          "Designer UI/UX",
          "Contributeur Open Source"
        ]
      }
    },
    about: {
      title: "Découvrez",
      emphasis: "QUI JE SUIS",
      sections: {
        skills: "Compétences Professionnelles",
        professional: "Compétences",
        skillset: "Professionnelles",
        tools: "Outils de Développement",
        toolsEmphasis: "Outils",
        toolsText: "que j'utilise"
      },
      resume: {
        title: "Vous voulez en savoir plus ?",
        description: "Téléchargez mon CV pour découvrir mon expérience et mes qualifications",
        button: "Télécharger le CV"
      },
      serviceRequest: {
        title: "Demande de Service Reçue !",
        service: "Service",
        tier: "Niveau",
        contact: "Nous vous contacterons au",
        assistance: "Pour une assistance immédiate"
      },
      intro: {
        title: "LAISSEZ-MOI",
        highlight: "ME PRÉSENTER",
        myself: "À VOUS",
        location: "de Buea, Cameroun",
        job: "développeur logiciel chez Njix",
        education: "Licence en Génie Logiciel",
        activities: "En dehors du code, voici ce que j'aime faire!",
        passion: "Je suis tombé amoureux de la programmation et j'ai au moins appris quelque chose, je pense..."
      },
      skills: {
        intro: "Je maîtrise",
        languages: "Javascript et React, Java, C++, Flutter et Dart"
      },
      interests: {
        intro: "Mes domaines d'intérêt sont le développement de",
        primary: "Technologies Web et Produits avec Js, Applications Mobiles avec Flutter",
        secondary: "Blockchain et IA"
      },
      flexibility: {
        intro: "Quand c'est nécessaire, j'applique aussi ma passion pour le développement avec",
        tech: "D'autres Technologies",
        and: "et beaucoup de",
        key: "flexibilité",
        outro: "selon les besoins de l'équipe et de l'entreprise",
        requirements: "Exigences"
      },
      social: {
        title: "RETROUVEZ-MOI SUR",
        intro: "N'hésitez pas à",
        connect: "me contacter",
        outro: ""
      },
      hobbies: [
        "Jouer aux Jeux Vidéo",
        "Regarder des Séries Sci-fi",
        "Regarder des Matchs de Football"
      ],
      quote: "S'efforcer de s'améliorer et faire la différence!"
    },
    projects: {
      title: {
        recent: "Mes Projets",
        works: "Récents"
      },
      subtitle: "Voici quelques projets sur lesquels j'ai travaillé récemment.",
      buttons: {
        github: "GitHub",
        demo: "Démo",
        viewCode: "Voir le code source",
        viewDemo: "Voir la démo en direct"
      },
      projectList: [
        {
          title: "Zap Nteng",
          description: "Une plateforme mobile et web pour la vente au détail de tous les produits de chaussures pour Zaps shoes. Construit avec React.js. Priorisant l'efficacité et optimisé pour l'expérience utilisateur et l'utilisabilité.",
          image: "/Assets/Projects/zapntengDemo.jpg",
          githubLink: "https://github.com/austin-elongo/zap-nteng",
          demoLink: ""
        },
        {
          title: "TPR USA",
          description: "Construit avec Shopify pour Triangle Party Rentals USA. Implémentation de paiements sécurisés intégrés, réservations sécurisées, chat sécurisé, favoris rapides, galerie et bien plus encore.",
          image: "/Assets/Projects/TPRDemo.jpg",
          githubLink: "",
          demoLink: "https://www.triangle-party-rentals.com/"
        },
        {
          title: "Editor.io",
          description: "Éditeur de code et de markdown en ligne construit avec react.js. Éditeur en ligne qui prend en charge le code html, css et js avec une vue instantanée du site web. Éditeur de markdown en ligne pour la création de fichiers README qui prend en charge GFM, les balises Html personnalisées avec une barre d'outils et un aperçu instantané. Les deux éditeurs prennent en charge la sauvegarde automatique du travail à l'aide du stockage local.",
          image: "/Assets/Projects/codeEditor.png",
          githubLink: "",
          demoLink: ""
        },
        {
          title: "Plant AI",
          description: "Utilisation du jeu de données sur les maladies des plantes de Kaggle et entraînement d'un modèle de classification d'images utilisant le framework 'PyTorch' avec CNN et Transfer Learning avec 38 classes de diverses feuilles de plantes. Le modèle a réussi à détecter les feuilles malades et saines de 14 plantes uniques. J'ai pu atteindre une précision de 98% en utilisant le modèle pré-entraîné Resnet34.",
          image: "/Assets/Projects/leaf.png",
          githubLink: "",
          demoLink: ""
        },
        {
          title: "Projet IA de Reconnaissance Pidgin",
          description: "Framework NLP basé sur le cloud qui améliore la reconnaissance contextuelle et les capacités de compréhension des assistants vocaux dans le secteur du e-commerce africain. Un projet construit avec Python, Jupiter Notebook, Js, Pandas, Numpy, Aws Lambda, PyTorch, Llama4 finetunning et Google colab",
          image: "/Assets/Projects/suicide.png",
          githubLink: "https://github.com/austin-elongo/Pidgin-Recognition-Ai",
          demoLink: "",
          docLink: "https://docs.google.com/document/d/1dxEKVdaeDyb5dZcRR5MjfJDc7DxYMV4M/edit?usp=sharing&ouid=103254439432642910469&rtpof=true&sd=true"
        },
        {
          title: "Site Personnel d'Austin",
          description: "Mon site web personnel construit avec MERN Stack. Il utilise React.js, Css, html, Ts et Versel pour le déploiement. Il est conçu avec un violet tech élégant qui est visuellement confortable et noir pour des raisons d'optimisation",
          image: "/Assets/Projects/portfolioDemo.jpg",
          githubLink: "https://github.com/austin-elongo/AustinElongo",
          demoLink: ""
        }
      ]
    },
    blog: {
      title: {
        insights: "Perspectives Technologiques &",
        tips: "Conseils de Développement"
      },
      subtitle: "Expertise en développement web et mobile au Cameroun",
      posts: [
        {
          title: "Pourquoi Votre Entreprise Camerounaise a Besoin d'une Application Mobile en 2024",
          summary: "Découvrez comment les applications mobiles peuvent transformer votre entreprise dans le marché numérique croissant du Cameroun...",
          date: "Février 2024",
          readTime: "7 min de lecture",
          tags: ["Entreprise", "Applications Mobiles", "Transformation Numérique"]
        },
        {
          title: "Solutions E-commerce pour le Cameroun : Un Guide Complet",
          summary: "Tout ce que vous devez savoir pour lancer une entreprise e-commerce au Cameroun...",
          date: "Février 2024",
          readTime: "7 min de lecture",
          tags: ["E-commerce", "Cameroon", "Online Business"]
        }
      ]
    },
    resume: {
      downloadButton: "Télécharger CV",
      pageTitle: "Mon CV",
      loading: "Chargement du PDF...",
      error: "Erreur de chargement du PDF"
    },
    footer: {
      designedBy: "Conçu et Développé par Austin Elongo",
      copyright: "Droits d'auteur © {year} AE",
      socialLinks: {
        github: "Visiter le profil GitHub",
        twitter: "Visiter le profil Twitter",
        linkedin: "Visiter le profil LinkedIn",
        instagram: "Visiter le profil Instagram"
      }
    },
    errors: {
      required: "Ce champ est obligatoire",
      invalidEmail: "Veuillez entrer une adresse email valide",
      invalidPhone: "Veuillez entrer un numéro de téléphone valide",
      minLength: "Doit contenir au moins {min} caractères",
      maxLength: "Ne doit pas dépasser {max} caractères",
      networkError: "Erreur réseau. Veuillez vérifier votre connexion",
      serverError: "Erreur serveur. Veuillez réessayer plus tard",
      unauthorized: "Accès non autorisé",
      notFound: "Ressource non trouvée",
      saved: "Enregistré avec succès",
      updated: "Mis à jour avec succès",
      deleted: "Supprimé avec succès",
      sent: "Envoyé avec succès"
    },
    meta: {
      defaultTitle: "Austin Elongo | Développeur Full Stack",
      defaultDescription: "Développeur Full Stack professionnel spécialisé dans le développement web, les applications mobiles et les solutions numériques au Cameroun",
      defaultKeywords: "développement web, applications mobiles, référencement, solutions numériques, Cameroun",
      languageSelector: "Changer la langue en",
      mainRegion: "Cameroun",
      businessHours: "Lun-Ven 9:00-17:00 WAT"
    }
  }
}; 