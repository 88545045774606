import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import Particle from "../Particle";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

function Projects() {
  const { language } = useLanguage();
  const t = translations[language].projects;

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t.title.recent} <strong className="purple">{t.title.works}</strong>
        </h1>
        <p style={{ color: "white" }}>
          {t.subtitle}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {t.projectList.map((project, index) => (
            <Col md={4} className="project-card" key={index}>
              <ProjectCard
                imgPath={project.image}
                isBlog={false}
                title={project.title}
                description={project.description}
                ghLink={project.githubLink}
                demoLink={project.demoLink}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
