// src/components/Home/Home2.js

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import myImg from "../../Assets/secondimg.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

function Home2() {
  const { language } = useLanguage();
  const t = translations[language].about;

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Person",
    "name": "Austin Elongo",
    "jobTitle": "Full Stack Developer",
    "description": "Professional Full Stack Developer specializing in web development and digital solutions",
    "url": "https://austinelongo.click",
    "sameAs": [
      "https://github.com/your-github",
      "https://linkedin.com/in/your-linkedin"
    ],
    "knowsAbout": [
      "Web Development",
      "Mobile App Development",
      "SEO",
      "Digital Solutions"
    ]
  };

  return (
    <Container fluid className="home-about-section" id="about">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <Link to="/register">
              <Button variant="primary" size="lg">
                Register for DevConvergence
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {t.intro.title} <span className="purple"> {t.intro.highlight} </span> {t.intro.myself}
            </h1>
            <p className="home-about-body">
              {t.intro.passion} 🤷‍♂️
              <br />
              <br />
              {t.skills.intro} 
              <i>
                <b className="purple">{t.skills.languages}</b>
              </i>
              <br />
              <br />
              {t.interests.intro} &nbsp;
              <i>
                <b className="purple">
                  {t.interests.primary}
                </b> and
                also in areas related to{" "}
                <b className="purple">
                  {t.interests.secondary}
                </b>
              </i>
              <br />
              <br />
              {t.flexibility.intro}
              <b className="purple">{t.flexibility.tech}</b> {t.flexibility.and}
              <i>
                <b className="purple">
                  {t.flexibility.key}
                </b>
              </i>
              &nbsp; {t.flexibility.outro}
              <i>
                <b className="purple">
                  {t.flexibility.requirements}
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t.social.title}</h1>
            <p>
              {t.social.intro} <span className="purple">{t.social.connect}</span> {t.social.outro}
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/austin-elongo"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/austin_elongo"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/austin-elongo"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/austin_elongo"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
