import React from 'react';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

export function SEO({ title, description, keywords, canonicalUrl }) {
  const { language } = useLanguage();
  const t = translations[language].meta;

  const defaultTitle = t.defaultTitle;
  const defaultDescription = t.defaultDescription;
  const defaultKeywords = t.defaultKeywords;

  return (
    <Helmet>
      <html lang={language} />
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:locale" content={language === 'en' ? 'en_US' : 'fr_FR'} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <link rel="alternate" hreflang="en" href={`https://austinelongo.click/en${window.location.pathname}`} />
      <link rel="alternate" hreflang="fr" href={`https://austinelongo.click/fr${window.location.pathname}`} />
    </Helmet>
  );
} 