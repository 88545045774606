import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import { FaDownload } from "react-icons/fa";
import Toolstack from "./Toolstack";
import { Helmet } from "react-helmet";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

function About() {
  const location = useLocation();
  const serviceRequest = location.state?.serviceRequest;
  const { language } = useLanguage();
  const t = translations[language].about;

  return (
    <Container fluid className="about-section">
      <Helmet>
        <title>{language === 'en' ? 'About Austin Elongo | Full Stack Developer' : 'À propos de Austin Elongo | Développeur Full Stack'}</title>
        <meta name="description" content={
          language === 'en' 
            ? "Learn about Austin Elongo, a Full Stack Developer specializing in web development, mobile apps, and digital solutions in Cameroon."
            : "Découvrez Austin Elongo, un développeur Full Stack spécialisé dans le développement web, les applications mobiles et les solutions numériques au Cameroun."
        } />
        <meta name="keywords" content="Austin Elongo, Full Stack Developer, Web Developer, Mobile App Developer, Cameroon Developer, Software Engineer" />
        <meta property="og:title" content="About Austin Elongo | Full Stack Developer" />
        <meta property="og:description" content="Professional Full Stack Developer with expertise in web development, mobile apps, and digital solutions." />
        <meta property="og:type" content="profile" />
        <link rel="canonical" href="https://austinelongo.click/about" />
      </Helmet>
      <Particle />
      <Container>
        {serviceRequest && (
          <div className="service-confirmation">
            <h2>{t.serviceRequest.title}</h2>
            <div className="request-details">
              <p>{t.serviceRequest.service}: {serviceRequest.service}</p>
              <p>{t.serviceRequest.tier}: {serviceRequest.tier}</p>
              <p>{t.serviceRequest.contact}: {serviceRequest.phoneNumber}</p>
            </div>
            <div className="contact-info">
              <p>{t.serviceRequest.assistance}</p>
              <p>WhatsApp: <a href="https://wa.me/237677690018">+237 677 69 00 18</a></p>
            </div>
          </div>
        )}

        <Row className="about-header">
          <Col
            md={7}
            className="about-content animate__animated animate__fadeIn"
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              {t.title} <strong className="purple">{t.emphasis}</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            className="about-img animate__animated animate__fadeInRight"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>

        <div className="section-divider">
          <h2 className="section-title">{t.sections.skills}</h2>
        </div>

        <h1 className="project-heading">
          {t.sections.professional} <strong className="purple">{t.sections.skillset}</strong>
        </h1>

        <Techstack />

        <div className="section-divider">
          <h2 className="section-title">{t.sections.tools}</h2>
        </div>

        <h1 className="project-heading">
          <strong className="purple">{t.sections.toolsEmphasis}</strong> {t.sections.toolsText}
        </h1>
        <Toolstack />

        <div className="resume-section">
          <h2>{t.resume.title}</h2>
          <p>{t.resume.description}</p>
          <button className="resume-btn">
            <FaDownload className="download-icon" /> {t.resume.button}
          </button>
        </div>

        <Github />
      </Container>
    </Container>
  );
}

export default About;
