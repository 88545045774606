import React, { useCallback } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import ReactCountryFlag from 'react-country-flag';
import './LanguageSwitcher.css';

function LanguageSwitcher() {
  const { language, toggleLanguage } = useLanguage();

  const countryCode = language === 'en' ? 'FR' : 'GB';
  const nextLanguage = language === 'en' ? 'French' : 'English';

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleLanguage();
  }, [toggleLanguage]);

  return (
    <button 
      className="language-switcher"
      onClick={handleClick}
      aria-label={`Switch to ${nextLanguage}`}
      type="button"
    >
      <ReactCountryFlag 
        countryCode={countryCode}
        svg
        style={{
          width: '1.2em',
          height: '1.2em',
          marginRight: '8px'
        }}
        aria-hidden="true"
      />
      <span>{language === 'en' ? 'FR' : 'EN'}</span>
    </button>
  );
}

export default LanguageSwitcher; 