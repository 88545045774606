import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

function AboutCard() {
  const { language } = useLanguage();
  const t = translations[language].about;

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t.intro.greeting} <span className="purple">Austin Elongo </span>
            {t.intro.from} <span className="purple"> {t.intro.location}</span>
            <br />
            {t.intro.job}
            <br />
            {t.intro.education}
            <br />
            <br />
            {t.intro.activities}
          </p>
          <ul>
            {t.hobbies.map((hobby, index) => (
              <li key={index} className="about-activity">
                <ImPointRight /> {hobby}
              </li>
            ))}
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "{t.quote}"{" "}
          </p>
          <footer className="blockquote-footer">AustinElongo</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
