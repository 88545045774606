import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Particle from "../Particle";
import getServicesData from "./ServicesData";
import "./Services.css";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';
import { SEO } from "../common/SEO";

function Services() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { language } = useLanguage();
  const t = translations[language].services;
  const servicesData = getServicesData(language);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Reset selections when component unmounts or when navigating away
  useEffect(() => {
    return () => {
      setSelectedService(null);
      setSelectedTier(null);
      setShowPhoneModal(false);
    };
  }, []);

  useEffect(() => {
    try {
      // Wrap your service data fetching in try-catch
      const data = getServicesData(language);
      console.log('Services Data:', data); // This will help us see the data structure
    } catch (error) {
      console.error('Services Error:', error);
    }
  }, [language]);

  const handleServiceSelection = (service, tier) => {
    try {
      // Prevent selecting if modal is already open
      if (showPhoneModal) return;

      console.log('Selected Service:', service); // Debug log
      console.log('Selected Tier:', tier); // Debug log

      setSelectedService(service);
      setSelectedTier(tier);
      setShowPhoneModal(true);
    } catch (error) {
      console.error('Service Selection Error:', error);
    }
  };

  const handleModalClose = () => {
    setShowPhoneModal(false);
    setPhoneNumber('');
  };

  const handleNavigation = (path, state) => {
    try {
      navigate(path, state);
    } catch (error) {
      console.error('Navigation error:', error);
      // Fallback to window.location if navigation fails
      window.location.href = path;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!selectedService || !selectedTier || !phoneNumber.trim()) {
      alert(t.errors.requiredFields);
      return;
    }

    const serviceRequest = {
      service: selectedService.title,
      tier: selectedTier,
      phoneNumber: phoneNumber.trim(),
      timestamp: new Date().toISOString()
    };

    // Show success modal first
    setShowPhoneModal(false);
    setShowSuccessModal(true);

    // After 3 seconds, navigate to contact page
    setTimeout(() => {
      setShowSuccessModal(false);
      setPhoneNumber('');
      setSelectedService(null);
      setSelectedTier(null);
      
      handleNavigation('/about', { state: { serviceRequest } });
    }, 3000);
  };

  useEffect(() => {
    // Simulate loading of services data
    const loadServices = async () => {
      try {
        setIsLoading(true);
        // Add any actual data loading here
        await new Promise(resolve => setTimeout(resolve, 500)); // Minimum loading time
      } catch (error) {
        console.error('Error loading services:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadServices();
  }, []);

  return (
    <Container fluid className="project-section">
      <SEO 
        title={t.meta.title}
        description={t.meta.description}
        keywords={t.meta.keywords}
        canonicalUrl={`https://austinelongo.click/${language}/services`}
      />
      
      <Particle />
      
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>{t.loading?.submitting || 'Loading...'}</p>
        </div>
      ) : (
      <Container>
        <h1 className="project-heading">
          {t.title}
        </h1>
        <p style={{ color: "white" }}>
          {t.subtitle}
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {servicesData.map((service, index) => (
            <Col key={index} md={6} className="project-card">
              <div className="service-card">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                
                <div className="service-tiers">
                  <div className="service-tier">
                    <h4 className="tier-name">{t.tiers.black}</h4>
                    <p className="tier-price">{service.tiers.black.price}</p>
                    <ul className="tier-features">
                      {service.tiers.black.features.map((feature, idx) => (
                        <li key={idx} className="tier-feature">{feature}</li>
                      ))}
                    </ul>
                    <button
                      className="select-button"
                      onClick={() => handleServiceSelection(service, 'Black')}
                      aria-label={`${t.buttons.select} ${t.tiers.black}`}
                    >
                      {t.buttons.select} {t.tiers.black}
                    </button>
                  </div>
                  
                  <div className="service-tier">
                    <h4 className="tier-name">{t.tiers.gold}</h4>
                    <p className="tier-price">{service.tiers.gold.price}</p>
                    <ul className="tier-features">
                      {service.tiers.gold.features.map((feature, idx) => (
                        <li key={idx} className="tier-feature">{feature}</li>
                      ))}
                    </ul>
                    <button
                      className="select-button"
                      onClick={() => handleServiceSelection(service, 'Gold')}
                      aria-label={`${t.buttons.select} ${t.tiers.gold}`}
                    >
                      {t.buttons.select} {t.tiers.gold}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      )}

      {showPhoneModal && (
        <div className="phone-modal" 
          role="dialog" 
          aria-modal="true"
          aria-label={t.modal.ariaLabel}
        >
          <div className="modal-content">
            <button 
              className="close-button" 
              onClick={handleModalClose}
              aria-label="Close modal"
            >
              ×
            </button>
            <h3>{t.modal.title}</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={t.modal.phone}
                required
                aria-label="Phone number"
                pattern="[0-9]+"
              />
              <div className="modal-buttons">
                <button type="submit">{t.buttons.continue}</button>
                <button type="button" onClick={handleModalClose}>{t.buttons.cancel}</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="success-modal" role="dialog" aria-modal="true">
          <div className="success-content">
            <h3>{t.modal.success}</h3>
            <p>{t.modal.successMessage}</p>
            <p>{t.modal.contactInfo} {phoneNumber}</p>
            <div className="loading-spinner"></div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Services; 