import React, { createContext, useState, useContext, useEffect } from 'react';
import { detectUserLanguage } from '../utils/languageDetection';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(
    detectUserLanguage()
  );

  const toggleLanguage = () => {
    const newLang = language === 'en' ? 'fr' : 'en';
    localStorage.setItem('preferredLanguage', newLang);
    setLanguage(newLang);
    window.dispatchEvent(new Event('languageChange'));
  };

  useEffect(() => {
    window.addEventListener('languageChange', () => {
      const storedLang = localStorage.getItem('preferredLanguage');
      if (storedLang && storedLang !== language) {
        setLanguage(storedLang);
      }
    });
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => useContext(LanguageContext); 