import React from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';
import BlogPost from './BlogPost';
import './Blog.css';

function Blog() {
  const { language } = useLanguage();
  const t = translations[language].blog;

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t.title.insights} <strong className="purple">{t.title.tips}</strong>
        </h1>
        <p style={{ color: "white" }}>
          {t.subtitle}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {t.posts.map((post, index) => (
            <BlogPost
              key={index}
              title={post.title}
              summary={post.summary}
              date={post.date}
              readTime={post.readTime}
              tags={post.tags}
            />
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Blog; 