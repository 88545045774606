export function detectUserLanguage() {
  // Check localStorage first
  const savedLanguage = localStorage.getItem('preferredLanguage');
  if (savedLanguage) {
    return savedLanguage;
  }

  // Check browser language
  const browserLang = navigator.language || navigator.userLanguage;
  const isFrench = browserLang.toLowerCase().startsWith('fr');
  
  // Default to English if not French
  return isFrench ? 'fr' : 'en';
} 