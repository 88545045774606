import React, { useState } from "react";
import "./ProjectCard.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

function ProjectCard(props) {
  const { language } = useLanguage();
  const t = translations[language].projects;
  const [imageError, setImageError] = useState(false);

  return (
    <Card className="project-card-view">
      <Card.Img
        variant="top"
        src={imageError ? "/projects/fallback.png" : props.imgPath}
        alt={props.title}
        onError={() => setImageError(true)}
      />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <div className="project-card-buttons">
          {props.ghLink && (
            <Button
              variant="primary"
              href={props.ghLink}
              target="_blank"
              aria-label={t.buttons.viewCode}
            >
              <BsGithub /> {t.buttons.github}
            </Button>
          )}

          {props.demoLink && (
            <Button
              variant="primary"
              href={props.demoLink}
              target="_blank"
              aria-label={t.buttons.viewDemo}
            >
              <CgWebsite /> {t.buttons.demo}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProjectCard; 